import {
  me,
  createUser,
  inviteUser,
  updateUser,
  findUsers,
  updateUsers,
  findUser,
  findUserNonAdmin,
  findUsersNonAdmin,
  acceptTerms,
} from "./api/users";

import { authUser, forgotPassword, resetPassword, validateInviteToken } from "./api/auth";

import {
  getJobListing,
  getWorkIdListing,
  getSubcontractsForAJobListing,
  getCreditorListing,
  getSubcontractClaimHeaderListing,
  getJobpacInvoices,
  getJobpacContracts,
  getJobpacProjects,
  getJobpacOrganisations,
  getJobpacInvoiceTransactionData,
} from "./api/integrations";

import {
  createOrganisation,
  updateOrganisation,
  updateOrganisationNonAdmin,
  findOrganisation,
  findOrganisations,
  deleteOrganisation,
  importOrgProject,
  importOrgContract,
  findOrgContracts,
  findOrgSubcontractors,
} from "./api/organisations";

import {
  findInvoice,
  findInvoices,
  importInvoice,
  updateInvoice,
  findInvoicesNonAdmin,
  findInvoiceNonAdmin,
  offerEarlyPayment,
  updateEarlyPayment,
  cancelEarlyPayment,
  approveEarlyPayment,
  findInvoiceActivities,
  createAPTransaction,
  updatePaymentDate,
  createNewPMWorksheet,
  rejectEarlyPayment,
  expireInvoice,
  invoicesSummaryNonAdmin,
  findEligibleInvoicesNonAdmin,
  findEligibleInvoiceNonAdmin,
  requestEarlyPaymentOnEligibleInvoice
} from "./api/invoices";

import {
  getAttachmentUrlById
} from "./api/attachments"

import {
  findProjectsNonAdmin,
  disableProjectNonAdmin,
  enableProjectNonAdmin,
  importProjectNonAdmin,
} from "./api/projects";

import {
  findExclusionsByOrganisationId,
  createExclusion,
  deleteExclusionById
} from "./api/exclusions"

import { decodeInviteToken } from "./api/misc";

export const getApi = () => {
  return {
    me,
    createUser,
    updateUser,
    findUsers,
    inviteUser,
    updateUsers,
    findUser,
    findUserNonAdmin,
    findUsersNonAdmin,
    acceptTerms,

    getJobListing,
    getWorkIdListing,
    getSubcontractsForAJobListing,
    getCreditorListing,
    getSubcontractClaimHeaderListing, // To be replaced by 'getJobpacInvoices'
    getJobpacInvoices,
    getJobpacContracts,
    getJobpacProjects,
    getJobpacOrganisations,
    getJobpacInvoiceTransactionData,

    authUser,
    forgotPassword,
    resetPassword,
    validateInviteToken,

    createOrganisation,
    updateOrganisation,
    updateOrganisationNonAdmin,
    findOrganisation,
    findOrganisations,
    deleteOrganisation,
    importOrgProject,
    importOrgContract,
    findOrgContracts,
    findOrgSubcontractors,

    findInvoice,
    findInvoiceNonAdmin,
    findInvoices,
    findInvoicesNonAdmin,
    importInvoice,
    updateInvoice,
    offerEarlyPayment,
    updateEarlyPayment,
    approveEarlyPayment,
    rejectEarlyPayment,
    cancelEarlyPayment,
    findInvoiceActivities,
    expireInvoice,
    invoicesSummaryNonAdmin,
    findEligibleInvoicesNonAdmin,
    findEligibleInvoiceNonAdmin,
    requestEarlyPaymentOnEligibleInvoice,

    decodeInviteToken,

    getAttachmentUrlById,

    createAPTransaction,
    updatePaymentDate,
    createNewPMWorksheet,

    findProjectsNonAdmin,
    disableProjectNonAdmin,
    enableProjectNonAdmin,
    importProjectNonAdmin,

    findExclusionsByOrganisationId,
    createExclusion,
    deleteExclusionById
  };
};
