import { DiscountWidget } from "@/components/invoices/DiscountWidget";
import { useInvoiceActionStore } from "@/stores/invoiceAction";
import { cn } from "@/utils/ui";
import {
  DiscountsType,
  InvoiceResponse,
  InvoiceStatusTypes,
  UpdateInvoicesRequest,
} from "@progresspay-next/dtos";
import {
  discountCalculator,
  organisationHelper,
} from "@progresspay-next/shared";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { App, Button, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import { useContextStore } from "../../stores/context";
import { getApi } from "../../utils/api";
import { queryKey } from "../../utils/query";
import { ConfirmDialog } from "../ui/confirm";
import { JobpacIntegrationAutomationModal } from "./JobpacIntegrationAutomationModal";

export const isSCWaitingForGCApprovingDiscount = (
  invoice: InvoiceResponse,
  isGC: boolean
) => invoice.invoice_status == InvoiceStatusTypes.REQUESTED && !isGC;

export const isWidgetLightStyled = (invoice: InvoiceResponse, isGC: boolean) =>
  isSCWaitingForGCApprovingDiscount(invoice, isGC) ||
  invoice.invoice_status == InvoiceStatusTypes.CANCELLED ||
  invoice.invoice_status == InvoiceStatusTypes.APPROVED;

export const InvoiceAction = ({
  invoice,
  discount,
}: {
  discount: {
    discount: number;
    payrun_date: string;
  } | null;
  invoice: InvoiceResponse;
}) => {
  const { message } = App.useApp();
  const api = getApi();
  const queryClient = useQueryClient();
  const contextStore = useContextStore();
  const isGC = contextStore.me?.is_gc;
  const invoiceUserActionStore = useInvoiceActionStore();

  const gcRejectEarlyPaymentRequest = useMutation({
    mutationFn: (payload: UpdateInvoicesRequest) => {
      return api.rejectEarlyPayment(payload);
    },
    onSuccess: (data) => {
      message.success("Early payment request has been successfully updated!");
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
  });

  const confirmEarlyPaymentRequest = useMutation({
    mutationFn: (payload: Partial<InvoiceResponse>) => {
      return api.updateEarlyPayment(payload);
    },
    onSuccess: (data) => {
      message.success("Early payment request has been successfully updated!");
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
    onError(error, variables, context) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(
          "There was an error confirming the early payment request."
        );
      }
    },
  });

  const approveEarlyPaymentRequest = useMutation({
    mutationFn: (payload: Partial<InvoiceResponse>) => {
      return api.approveEarlyPayment(payload);
    },
    onSuccess: (data) => {
      message.success("Early payment request has been successfully approved!");
      invoiceUserActionStore.setUserActionTargetId(invoice.id);
      // JOBPAC integration automation
      if (
        data.contract?.organisation &&
        organisationHelper.isERPJobpac(data.contract?.organisation)
      ) {
        setIsJobpacIntegrationAutomationModalOpen(true);
      } else {
        queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
      }
    },
    onError(error, variables, context) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(
          "There was an error approving the early payment request."
        );
      }
    },
  });

  const cancelEarlyPaymentRequest = useMutation({
    mutationFn: (payload: Partial<InvoiceResponse>) => {
      return api.cancelEarlyPayment(payload);
    },
    onSuccess: (data) => {
      message.success("Early payment request has been successfully cancelled!");
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
    onError(error, variables, context) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(
          "There was an error cancelling the early payment request."
        );
      }
    },
  });

  const confirmDiscountSC = () => {
    if (discount) {
      const payload = {
        id: invoice.id,
        discount: discount,
      };
      confirmEarlyPaymentRequest.mutate(payload);
    }
  };

  // Only SUBMITTED and APRROVED in Payapps
  const externalInvoiceStatus = {
    isSubmitted: () => invoice.external_data.claim_status === "SUBMITTED",
    isApproved: () => invoice.external_data.claim_status === "APPROVED",
  };

  const invoiceState = {
    isPendingSCSubmission: () =>
      !confirmEarlyPaymentRequest.isSuccess &&
      [
        InvoiceStatusTypes.CREATED,
        InvoiceStatusTypes.INTENDED,
        InvoiceStatusTypes.AVAILABLE,
        InvoiceStatusTypes.OFFERED,
      ].includes(invoice.invoice_status as InvoiceStatusTypes),
    isPendingGCApproval: () =>
      ((confirmEarlyPaymentRequest.isSuccess ||
        invoice.invoice_status == InvoiceStatusTypes.REQUESTED) &&
        !approveEarlyPaymentRequest.isSuccess) ||
      isJobpacIntegrationAutomationModalOpen,
    isApproved: () =>
      approveEarlyPaymentRequest.isSuccess ||
      invoice.invoice_status == InvoiceStatusTypes.APPROVED,
  };

  const [isCancelRequestConfirmOpen, setIsCancelRequestConfirmOpen] =
    useState<boolean>(false);

  const [isApproveRequestConfirmOpen, setIsApproveRequestConfirmOpen] =
    useState<boolean>(false);

  const [isRejectRequestConfirmOpen, setIsRejectRequestConfirmOpen] =
    useState<boolean>(false);

  const [
    isJobpacIntegrationAutomationModalOpen,
    setIsJobpacIntegrationAutomationModalOpen,
  ] = useState<boolean>(false);

  let actionNode = null;
  if (isGC && externalInvoiceStatus.isSubmitted()) {
    actionNode = (
      <Button
        type="primary"
        className="w-full rounded-full disabled:text-gray-500"
        size="large"
        disabled={true}
      >
        Claim Pending Approval in Payapps
      </Button>
    );
  } else if (invoiceState.isPendingSCSubmission()) {
    if (isGC) {
      actionNode = (
        <Button
          type="primary"
          className="w-full rounded-full disabled:text-gray-500"
          size="large"
          disabled={true}
        >
          Pending Subcontractor Confirming Discount
        </Button>
      );
    } else {
      actionNode = (
        <>
          <Button
            type="primary"
            className="w-full rounded-full disabled:text-gray-500"
            size="large"
            disabled={discount === null}
            loading={confirmEarlyPaymentRequest.isPending}
            onClick={() => {
              confirmDiscountSC();
            }}
          >
            Request Early Payment
          </Button>
        </>
      );
    }
  } else if (invoiceState.isPendingGCApproval()) {
    if (isGC && externalInvoiceStatus.isApproved()) {
      actionNode = (
        <div className="flex flex-row flex-nowrap gap-2">
          <Button
            type="primary"
            className="w-full rounded-full disabled:text-gray-500"
            size="large"
            disabled={discount === null || !invoice.can_approve}
            onClick={() => {
              setIsApproveRequestConfirmOpen(true);
            }}
            loading={approveEarlyPaymentRequest.isPending}
          >
            Approve
          </Button>
          <ConfirmDialog
            open={isApproveRequestConfirmOpen}
            onOpenChange={(v) => setIsApproveRequestConfirmOpen(v)}
            callback={() => {
              approveEarlyPaymentRequest.mutate({
                id: invoice.id,
                discount: invoice.discount,
              });
            }}
            title={`Are you sure you would like to approve this early payment request?`}
          ></ConfirmDialog>
          <Button
            className="w-full rounded-full disabled:text-gray-500"
            size="large"
            disabled={discount === null || !invoice.can_approve}
            onClick={() => {
              setIsRejectRequestConfirmOpen(true);
            }}
            loading={gcRejectEarlyPaymentRequest.isPending}
          >
            Reject
          </Button>
          <ConfirmDialog
            open={isRejectRequestConfirmOpen}
            onOpenChange={(v) => setIsRejectRequestConfirmOpen(v)}
            callback={() => {
              gcRejectEarlyPaymentRequest.mutate({
                id: invoice.id,
              });
            }}
            title={`Are you sure you would like to reject this early payment request?`}
          ></ConfirmDialog>
        </div>
      );
    } else {
      actionNode = (
        <>
          <Button
            className="w-full rounded-full bg-white"
            size="large"
            onClick={() => {
              setIsCancelRequestConfirmOpen(true);
            }}
            loading={cancelEarlyPaymentRequest.isPending}
          >
            Cancel Request
          </Button>
          <ConfirmDialog
            open={isCancelRequestConfirmOpen}
            onOpenChange={(v) => setIsCancelRequestConfirmOpen(v)}
            callback={() => {
              cancelEarlyPaymentRequest.mutate({
                id: invoice.id,
              });
            }}
            title={`Are you sure you would like to cancel your early payment request?`}
          >
            By cancelling you may not be able to resubmit another request if
            early payment is no longer available.
          </ConfirmDialog>
        </>
      );
    }
  } else {
    actionNode = null;
  }
  return (
    <>
      {actionNode}
      {/* Always have modal here to prevent it from closing prematurely */}
      <JobpacIntegrationAutomationModal
        open={isJobpacIntegrationAutomationModalOpen}
        onOpenChange={(v) => {
          setIsJobpacIntegrationAutomationModalOpen(v);
        }}
        invoice={invoice}
      ></JobpacIntegrationAutomationModal>
    </>
  );
};

export const RequestEarlyPaymentWidget = (prop: any) => {
  const { invoice }: { invoice: InvoiceResponse } = prop;
  const api = getApi();
  const meDetails = useQuery({
    queryKey: ["me"],
    queryFn: api.me,
  });
  const [selectedPaymentDate, setSelectedPaymentDate] = useState<Date | null>(
    () => {
      if (invoice.discount?.payrun_date) {
        return moment(invoice.discount?.payrun_date).toDate();
      }
      return null;
    }
  );
  const [discount, setDiscount] = useState<string>(() =>
    invoice.discount?.discount ? `${invoice.discount?.discount}` : ""
  );

  const {
    original_payment_due_date,
    external_data: { net_claim_incl_tax },
  } = invoice;

  const isCalculationReady =
    Boolean(discount && !isNaN(Number(discount))) &&
    typeof selectedPaymentDate == "object";
  let earlyPaymentDiscount = null;
  let daysEarly = null;
  if (isCalculationReady) {
    earlyPaymentDiscount = discountCalculator(
      net_claim_incl_tax,
      {
        payrun_date: moment(selectedPaymentDate).format("YYYY-MM-DD"),
        discount: Number(discount),
      },
      10,
      invoice?.contract?.organisation?.settings?.pp_fee
    );
    daysEarly = moment(original_payment_due_date).diff(
      selectedPaymentDate,
      "days"
    );
  }
  // Should discount configuration be inherit from parent org?
  let discountsSetting = invoice.contract?.organisation?.discounts;
  if (!discountsSetting && invoice.contract?.organisation?.parent) {
    discountsSetting = invoice.contract?.organisation?.parent?.discounts;
  }
  const discountsConfiguration =
    discountsSetting && discountsSetting.payment_runs
      ? discountsSetting.payment_runs
      : [];
  const dynamicDiscountsConfiguration =
    discountsSetting?.dynamic_discounts ?? [];

  return discountsSetting ? (
    <div
      className={cn(
        `rounded-lg p-6`,
        !isWidgetLightStyled(invoice, !!meDetails.data?.is_gc) &&
          `bg-slate-800 text-white`,
        isWidgetLightStyled(invoice, !!meDetails.data?.is_gc) && `bg-gray-100`
      )}
    >
      <DiscountWidget
        type={(discountsSetting as DiscountsType).display_type}
        invoice={invoice}
        discountConfiguration={discountsConfiguration}
        dynamicDiscountConfiguration={dynamicDiscountsConfiguration}
        onChange={(discount) => {
          setDiscount(`${discount.discount}`);
          setSelectedPaymentDate(moment(discount.payrun_date).toDate());
        }}
      />
      <Row className="pt-4">
        <InvoiceAction
          discount={
            isCalculationReady
              ? {
                  discount: Number(discount),
                  payrun_date: selectedPaymentDate
                    ? moment(selectedPaymentDate).format("YYYY-MM-DD")
                    : "",
                }
              : null
          }
          invoice={invoice}
        />
      </Row>
    </div>
  ) : (
    <div className="py-4 text-center">Discounts not configured.</div>
  );
};
