import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { useState } from "react";
import { Expand, Shrink } from "lucide-react";
import { cn } from "@/utils/ui";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getApi } from "@/utils/api";

export const EULAModal = () => {
  const api = getApi();
  const queryClient = useQueryClient();
  const me = useQuery({
    queryKey: ["me"],
    queryFn: api.me,
  });
  const acceptTermsMutation = useMutation({
    mutationFn: (id: string) => api.acceptTerms(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"]});
    },
  });
  const [isWide, setIsWide] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  return (
    <Dialog open={me.isSuccess && !me.data.terms_accepted && !me.data.is_gc}>
      <DialogContent
        noClose
        className={cn(
          "sm:max-w-none w-auto transition-all",
          isWide ? "left-8 right-8" : `left-[25%] right-[25%]`
        )}
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle className="flex flex-row flex-nowrap justify-between">
            <div className="text-3xl my-4">
              ProgressPay End User License Agreement (EULA)
            </div>
            <div>
              {isWide ? (
                <Button variant={"ghost"} onClick={(e) => setIsWide(false)}>
                  <Shrink className="h-4 w-4" />
                </Button>
              ) : (
                <Button variant={"ghost"} onClick={(e) => setIsWide(true)}>
                  <Expand className="h-4 w-4" />
                </Button>
              )}
            </div>
          </DialogTitle>
        </DialogHeader>
        <div
          className={cn(
            "max-h-[40vh] bg-gray-100 text-gray-500 overflow-y-auto p-4 border-b-2 border-b-blue-500 leading-6"
          )}
        >
          <p>
            <strong className="my-2 inline-block">Acceptance of Terms</strong>
          </p>
          <p>
            1. By accessing the Website or using the Service, you accept and
            agree to be bound by these terms.{" "}
          </p>
          <p>
            2. These terms are subject to change without prior written notice at
            any time, at ProgressPay’s sole discretion. The End User’s use of
            the services after any change in these Terms posted on the Website
            will be taken as acceptance of and agreement to those changes.{" "}
          </p>
          <p>
            3. ProgressPay simply provides a marketplace where you can seek
            early payment of your invoices. It cannot guarantee when or the
            terms on which your debtors will pay your invoices.
          </p>
          <p>
            <strong className="my-2 inline-block">License</strong>
          </p>
          <p>
            4. Provided you comply with these terms, you will have a
            non-exclusive, royalty-free and non-transferable license to access
            and use the Service but:{" "}
          </p>
          <p>4.1. not to permit any third party to access the Service;</p>
          <p>4.2. not to create any derivative works based on the Service;</p>
          <p>
            4.3. not to modify, reverse engineer, translate, disassemble, or
            decompile the Service or cause or permit others to do so;
          </p>
          <p>
            4.4. not to build, market or promote any competing product or
            service;
          </p>
          <p>
            4.5. not to copy or imitate the look, features, functions or
            graphics of the Website;
          </p>
          <p>
            4.6. not to republish any information, data, artistic work,
            trademark or distinguishing mark obtained from the Service or the
            Website;
          </p>
          <p>
            4.7. not to install in your own website or documentation or
            marketing collateral any hyperlink to the Website;
          </p>
          <p>
            4.8. not to pass off the Service as exclusively available to persons
            engaging your services.
          </p>
          <p>
            5. ProgressPay reserves all rights in and to the Service other than
            those expressly granted by these terms.{" "}
          </p>
          <p>
            6. Nothing in these terms limit in any way ProgressPay’s right to
            develop, publish, use, license, create derivative works of, or
            otherwise exploit the Service or to permit third parties to do so.{" "}
          </p>
          <p>
            7. ProgressPay will use every endeavour to support your inquiries
            within a reasonable time, so long as you co-operate (including by
            providing information needed to replicate, diagnose, and remedy any
            error or non-functionality preventing access to the Website or
            efficient use of the Service).
          </p>
          <p>
            8. You must not share usernames and passwords with anyone else.
            ProgressPay will not be liable for any loss that you may incur as a
            result of someone else using those passwords or accounts, either
            with or without your knowledge.{" "}
          </p>
          <p>
            9. ProgressPay may terminate this license at any time without
            expressing a reason. In that event, ProgressPay will permanently
            erase all Content you have created from the Service.
          </p>
          <p>
            10. You will retain all intellectual property rights, title, and
            interests in Content. Otherwise, ProgressPay owns all intellectual
            property rights, title, and interests in and to the Service and the
            data stored by it. ProgressPay is not obliged to maintain, store or
            provide anyone else with access to your Content.
          </p>
          <p>11. ProgressPay may </p>
          <p>
            11.1. monitor, collect and analyze data about the provision, use and
            performance of the Service to you;
          </p>
          <p>
            11.2. use any such data to improve and enhance the services
            (including sharing this data with other debtors using the platform
            to assist in their decision making as to whether to make early
            payment offers to you or approve your early payment requests);
          </p>
          <p>
            11.3. use any such data to diagnose and correct bugs, errors and
            non-functionality of the Service;
          </p>
          <p>
            11.4. aggregate, use or publish any such data in de-identified form
            in the course of its business; and;
          </p>
          <p>
            11.5. use and publish your name and advertise your willingness to
            negotiate terms or a discount in return for early payment of your
            invoices{" "}
          </p>
          <p>
            <strong className="my-2 inline-block">Access and Monitoring</strong>
          </p>
          <p>
            12. ProgressPay may monitor the use of or access to your account and
            any Content created by you in order to identify or resolve technical
            problems or respond to complaints about the Service or as may be
            required by law. ProgressPay may remove any Content uploaded to the
            Service in violation of these terms.{" "}
          </p>
          Mis-use of the Service
          <p>13. You must not use the Service </p>
          <p>
            13.1. to seek credit for any personal, domestic or household
            purposes or any other purpose;
          </p>
          <p>
            13.2. to store or transmit data or comment that defames any person;{" "}
          </p>
          <p>
            13.3. to store or transmit any data, symbol or comment, the
            possession or publication of which attracts any criminal or civil
            penalty;
          </p>
          <p>
            13.4. to store or transmit private or confidential information about
            anyone else;
          </p>
          <p>13.5. to store or transmit Malicious Code; </p>
          <p>
            13.6. in a way that interferes with or disrupts the integrity or
            performance of the Service;
          </p>
          <p>
            13.7. to gain unauthorized access to the networks or data or private
            information of any other customer of ProgressPay;
          </p>
          <p>13.8. to seek advice about financial products; or</p>
          <p>
            13.9. to seek custodial, depository, trustee, superannuation,
            crowd-funding, insurance claims handling or insurance claims
            settling services.
          </p>
          <p>
            <strong className="my-2 inline-block">Breach</strong>
          </p>
          <p>
            14. You will breach this licence and ProgressPay may terminate your
            account if:
          </p>
          <p>14.1. you misuse the Service;</p>
          <p>14.2. you are in breach of any term of this licence;</p>
          <p>
            14.3. an external controller is appointed to manage your assets,
            undertakings or affairs;{" "}
          </p>
          <p>
            14.4. the holder of any Security over your assets demands that you
            deliver up possession of any of those assets or threatens the
            appointment of a receiver of those assets;{" "}
          </p>
          <p>
            14.5. any moratorium is imposed over the enforcement of your debts;{" "}
          </p>
          <p>
            14.6. you make or propose any composition or arrangement with your
            creditors by which your assets are subjected to the control of any
            of those creditors or anyone else;
          </p>
          <p>
            14.7. you admit that you are or are deemed by any law to be unable
            to pay your debts as and when they fall due;
          </p>
          <p>
            14.8. you fail to conduct your business and financial affairs in a
            proper, orderly and efficient manner and keep proper financial,
            accounting and operating records;{" "}
          </p>
          <p>
            14.9. you cease conducting your business or significantly change the
            nature of your business;{" "}
          </p>
          <p>
            14.10. you fail to obtain, renew on time and comply with the
            conditions of every licence needed to conduct your business;{" "}
          </p>
          <p>
            14.11. in the case that you are a trustee, that you retire or is
            replaced or cease to be a trustee or that the trust is ended or that
            all the trust assets are distributed or the terms of the trust terms
            are changed;{" "}
          </p>
          <p>
            14.12. you fail to notify ProgressPay within seven days of any
            change to your registered corporate or trading name, your registered
            office or principal place of business or any change to your equity
            structure or ownership arrangements.{" "}
          </p>
          <p>
            <strong className="my-2 inline-block">Termination</strong>
          </p>
          <p>
            15. If ProgressPay chooses to terminate this licence, then it will
            be excused from the further performance of all future Commitments.
            However, the termination will not prevent ProgressPay from
            exercising its rights and remedies against you arising from any
            antecedent breach.{" "}
          </p>
          <p>
            <strong className="my-2 inline-block">Limitation of Liability</strong>
          </p>
          <p>16. ProgressPay shall not be liable to you </p>
          <p>
            16.1. for any loss of profits, goodwill, reputation, loss of
            business opportunities or anticipated savings, or for special,
            punitive, indirect or consequential damages arising directly or
            indirectly from the use or non-functionality of the Service
          </p>
          <p>
            16.2. for any claim, action, suit or demand arising directly or
            indirectly from or in connection with events outside its direct
            control including server outages or telecommunications, network or
            power failures
          </p>
          <p>
            16.3. for any claim, action, suit or demand whatsoever arising
            directly or indirectly from or in connection with any error or
            non-functionality of the Service unless you have first given
            ProgressPay written notice specifying the particular error or
            failure to function and precisely when it occurred.
          </p>
          <p>
            16.4. for any advice, statement, representation, recommendation or
            assistance given by ProgressPay in relation to or in connection
            with:
          </p>
          <p>
            16.4.1. the willingness or otherwise of your debtors to pay an
            invoice early;
          </p>
          <p>
            16.4.2. the terms of any agreement or arrangement between you and
            your debtors for early payment of your invoices;
          </p>
          <p>
            16.4.3. the terms on which your debtors may be willing to consider
            early payment of your invoices;
          </p>
          <p>16.4.4. the time-value of early payment;</p>
          <p>
            16.4.5. an appropriate discount rate to be applied for early payment
            of any invoice; or
          </p>
          <p>
            16.4.6. the tax, accounting or other effects of early payment of an
            invoice.
          </p>
          <p>
            <strong className="my-2 inline-block">Indemnities</strong>
          </p>
          <p>
            17. You will indemnify ProgressPay against all claims, demands,
            losses, expenses, liabilities whatsoever suffered or incurred by
            ProgressPay arising (directly or indirectly) from or in connection
            with any breach by you of this licence.
          </p>
          <p>
            18. You will indemnify ProgressPay against all legal professional
            costs and disbursements actually incurred in enforcing or attempting
            to enforce its rights and entitlements under this licence on a full
            indemnity basis.{" "}
          </p>
          <p>
            <strong className="my-2 inline-block">Variation</strong>
          </p>
          <p>
            19. No amendment or variation to this licence will be effective
            unless it is in writing and signed by ProgressPay.
          </p>
          <p>
            <strong className="my-2 inline-block">Severability</strong>
          </p>
          <p>
            20. This licence is to be read as if it omitted illegal, invalid or
            unenforceable terms, but only if the remaining terms have an
            unambiguous meaning and effect. For that purpose, this licence may
            be read in different ways in various jurisdictions.
          </p>
          <p>
            <strong className="my-2 inline-block">Governing Law</strong>
          </p>
          <p>
            21. This licence will be governed by and construed in accordance
            with the laws in force in New South Wales and each Party will submit
            to the jurisdiction of its courts.
          </p>
          <p>
            <strong className="my-2 inline-block">Entire Agreement</strong>
          </p>
          <p>
            22. You admit that you have not relied on any statement,
            representation recommendation or assurance given to you by
            ProgressPay but not expressed in this licence (including that
            services will meet your needs, that the operation of the services
            will be uninterrupted, or that the Service will function in
            conformity with any documentation provided to you by ProgressPay).{" "}
          </p>
          <p>
            23. So far as allowed by law, no additional terms (including terms
            about merchantability, due care or fitness for purpose) are to be
            implied into this licence.{" "}
          </p>
          <p>
            <strong className="my-2 inline-block">Dictionary</strong>
          </p>
          <p>24. In this licence:</p>
          “person” includes a company, corporation, firm or body of persons.
          “Content” means certain data, content, or materials provided by the
          End User when using the SAAS Service. The End User is solely
          responsible for the accuracy, quality, integrity, legality,
          reliability, appropriateness, and copyright of all End User Content.
          The End User shall obtain and maintain any rights, consents, and
          approvals required to grant ProgressPay and authorized third parties
          the right to access and use any End User Content for the purposes
          described herein. “Malicious Code” means viruses, worms, time bombs,
          Trojan horses, and other harmful or malicious code, files, scripts or
          agents. “Service” means the web-based, on-line software as a service
          accessed via the Website. “Website” means the ProgressPay platform.
          <p>
            <strong className="my-2 inline-block">Interpretation</strong>
          </p>
          <p>25. In this document:</p>
          <p>25.1. the singular includes the plural and vice versa.</p>
          <p>25.2. a gender includes each other gender.</p>
          <p>
            25.3. headings are for convenience only and do not affect the
            meaning or effect of this document
          </p>
          <p>25.4. the word “including” is not a term of limitation.</p>
        </div>
        <div className="my-4">
          <div className="items-top flex space-x-2">
            <Checkbox
              id="eula-checkbox"
              checked={isAccepted}
              onCheckedChange={(c) => setIsAccepted(!!c)}
            />
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor="eula-checkbox"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                I have read and agree to the End User License Agreement above
              </label>
            </div>
          </div>
        </div>
        <DialogFooter>
          {/* <Button variant={"outline"}>Cancel</Button> */}
          <Button
            disabled={!isAccepted}
            onClick={() => {
              if (me.isSuccess) {
                acceptTermsMutation.mutate(me.data.id);
              }
            }}
          >
            Accept
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
