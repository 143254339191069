import { ClockCircleOutlined, MailOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import moment from "moment";
import { ActivityVerb, InvoiceResponse } from "@progresspay-next/dtos";
import { useQueryInvoiceActivities } from "../../utils/query";
import { momentjsFormat } from "@progresspay-next/shared";
import { Calendar, Mail } from "lucide-react";

export const InvoiceAuditTrail = ({ invoice }: { invoice: InvoiceResponse }) => {
  const { data: activities } = useQueryInvoiceActivities(invoice.id as string);

  const isRequest = !!activities?.find(activity => ActivityVerb.EARLY_PAYMENT_REQUEST_CREATED === activity.verb);

  const extractEmails = (metadata: any) => {
    const { response } = metadata;
    const sentArray = response.accepted || response;

    if (typeof sentArray[0] === "string") {
      return sentArray.map((email: string) => `${email}`).join(", ");
    }

    return response.map((r: any) => `${r.email}`).join(", ");
  };

  const auditCopy = (activity: NonNullable<typeof activities>[number]) => {
    const name = [
      ActivityVerb.THANK_YOU_FOR_EARLY_PAYMENT_INTEREST_SC_SENT,
      ActivityVerb.THANK_YOU_FOR_EARLY_PAYMENT_INTENT_SC_SENT,
      ActivityVerb.THANK_YOU_FOR_EARLY_PAYMENT_REQUEST_SC_SENT,
      ActivityVerb.EARLY_PAYMENT_REQUEST_APPROVED_SC_SENT,
      ActivityVerb.EARLY_PAYMENT_REQUEST_REJECTED_SC_SENT,
      ActivityVerb.EARLY_PAYMENT_REQUESTED_GC_SENT,
      ActivityVerb.ELIGIBLE_NOTIFICATION_SENT,
    ].includes(activity.verb)
      ? extractEmails(activity.metadata)
      : activity?.user?.first_last;

    const output: {
      [key: string]: React.ReactNode;
    } = {
      EARLY_PAYMENT_REQUEST_CREATED: (
        <span>{name} created this payment request</span>
      ),
      EARLY_PAYMENT_INTENT_CREATED: (
        <span>{name} created this payment intent</span>
      ),
      ELIGIBLE_NOTIFICATION_SENT: (
        <span>Eligible notification was sent to {name}</span>
      ),
      THANK_YOU_FOR_EARLY_PAYMENT_INTEREST_SC_SENT: (
        <span>Early payment registration email sent to {name}</span>
      ),
      EARLY_PAYMENT_INTENT_UPDATED: (
        <span>{name} updated this payment {isRequest ? 'request' : 'intent'}</span>
      ),
      EARLY_PAYMENT_INTENT_SUBMITTED: <span>Claim approved by {name}</span>,

      EARLY_PAYMENT_REQUEST_SENT: (
        <span>Early payment request submitted by {name}</span>
      ),
      EARLY_PAYMENT_REQUEST_AVAILABLE: (
        <span>Early payment request made available by {name}</span>
      ),
      EARLY_PAYMENT_REQUEST_APPROVED: (
        <span>Early payment request approved by {name}</span>
      ),
      EARLY_PAYMENT_REQUEST_UPDATED: (
        <span>Early payment request updated by {name}</span>
      ),
      EARLY_PAYMENT_REQUEST_SUBMITTED: (
        <span>Early payment request submitted by {name}</span>
      ),

      EARLY_PAYMENT_REQUEST_APPROVAL_FAILED: (
        <span>{name} failed to approve early payment request</span>
      ),
      EARLY_PAYMENT_REQUEST_CANCELLED: (
        <span>Early payment request cancelled by {name}</span>
      ),
      EARLY_PAYMENT_REQUEST_REJECTED: (
        <span>Early payment request rejected by {name}</span>
      ),
      ADDENDUM_NOTIFICATION_SENT: (
        <span>Addendum notification sent to {name}</span>
      ),
      EARLY_PAYMENT_REQUEST_APPROVED_SC_SENT: (
        <span>Approval notification sent to {name}</span>
      ),
      EARLY_PAYMENT_REQUEST_REJECTED_SC_SENT: (
        <span>Rejected notification sent to {name}</span>
      ),
      THANK_YOU_FOR_EARLY_PAYMENT_INTENT_SC_SENT: (
        <span>Early payment intent notification sent to {name}</span>
      ),
      THANK_YOU_FOR_EARLY_PAYMENT_REQUEST_SC_SENT: (
        <span>Early payment request notification sent to {name}</span>
      ),
      EARLY_PAYMENT_REQUESTED_GC_SENT: (
        <span>Early payment request notification sent to {name}</span>
      ),

      JOBPAC_UPDATE_PAYMENT_DATE: <span>Payment date updated in Jobpac</span>,
      JOBPAC_UPDATE_PAYMENT_DATE_FAILED: (
        <span>Failed to upate Payment date in Jobpac</span>
      ),
      JOBPAC_SYNC: <span>Successful Jobpac sync</span>,
      JOBPAC_SYNC_FAILED: <span>Jobpac sync failed</span>,
      JOBPAC_ADD_CREDIT_NOTE_CREDITOR: (
        <span>Creditor credit note added in Jobpac</span>
      ),
      JOBPAC_ADD_CREDIT_NOTE_CREDITOR_FAILED: (
        <span>Attempt to add creditor credit note in Jobpac failed</span>
      ),
      JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY: (
        <span>Payment date updated in Jobpac</span>
      ),
      JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY_FAILED: (
        <span>Attempt to add ProgressPay credit note in Jobpac failed</span>
      ),
      JOBPAC_ADD_NEW_PM_WORKSHEET: (
        <span>New Project manager worksheet added in Jobpac</span>
      ),
      JOBPAC_ADD_NEW_PM_WORKSHEET_FAILED: (
        <span>
          Attempt to add new Project manager worksheet in Jobpac failed
        </span>
      ),

      EXPIRED: <span>Request marked as expired by {name}</span>,
    };

    const missingVerbCopy = (verb: string) => {
      console.warn(`${verb} activity missing`);

      return null;
    };

    return output[activity.verb]
      ? output[activity.verb]
      : missingVerbCopy(activity.verb);
  };

  const excludedActivities: string[] = [];

  const getMandrillEvents = (
    activity: NonNullable<typeof activities>[number]
  ) => {
    if (!activity.mandrill_events) {
      return [];
    }
    const recipients = Array.isArray(activity.metadata?.response)
      ? activity.metadata?.response
      : [];
    const events = [];
    for (let r of recipients) {
      for (let e of activity.mandrill_events) {
        if (r.email == e.msg?.email) {
          events.push({
            email: r.email,
            isMultipleEmails: recipients.length > 1,
            eventNameHuman: e.event!.replaceAll("_", " "),
            ...e,
          });
        }
      }
    }
    // Sort
    return events.sort((e1, e2) => {
      return moment(e1.created_at).isAfter(moment(e2.created_at)) ? -1 : 1;
    });
  };

  return (
    <>
      {activities?.length ? (
        <div className="text-sm">
          {activities.map((a, index) => {
            if (excludedActivities.includes(a.verb)) {
              return null;
            }
            const Icon = a.verb.includes("SENT")
              ? MailOutlined
              : ClockCircleOutlined;

            return (
              <div key={a.id} className="px-2 py-2">
                <div className="flex flex-row flex-nowrap items-stretch">
                  <div className="flex flex-col flex-nowrap items-start">
                    <Icon className="text-base my-1 mr-4 flex items-center" />
                    {index < activities.length - 1 ? (
                      <div className="ml-2 -mb-2 mt-1 w-[1px] bg-slate-300 flex-auto"></div>
                    ) : null}
                  </div>
                  <div>
                    <div className="">{auditCopy(a)}</div>
                    <div className="mt-1 text-gray-400 min-w-[150px] flex flex-row flex-nowrap gap-2 items-center">
                      <Calendar className="w-4 h-4" />
                      {moment(a.created_at).format(momentjsFormat.dateTime)}
                    </div>
                    {getMandrillEvents(a).length ? (
                      <div className="my-3">
                        <ul>
                          {getMandrillEvents(a).map((e) => (
                            <li className="text-gray-500" key={e.id}>
                              <div className="flex flex-row gap-2 items-center">
                                <Mail className="w-4 h-4" />
                                <span>{e.eventNameHuman}</span>
                                {e.isMultipleEmails ? (
                                  <span>({e.email})</span>
                                ) : null}
                              </div>
                              <div className="text-gray-400 flex flex-row py-1">
                                <span className="w-[1px] ml-2 mr-4 bg-slate-300"></span>
                                <span className="mb-1">
                                  {moment(e.created_at).format(
                                    momentjsFormat.dateTime
                                  )}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Empty />
      )}
    </>
  );
};
