"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoicesSummaryRequestSchema = exports.RequestEarlyPaymentOnEligibleInvoiceRequestSchema = exports.InvoicesRequestSchema = exports.UpdateInvoicesRequestSchema = exports.CreateInvoiceRequestSchema = void 0;
const zod_1 = require("zod");
const invoice_type_1 = require("../types/invoice.type");
exports.CreateInvoiceRequestSchema = invoice_type_1.InvoiceTypeSchema.partial();
exports.UpdateInvoicesRequestSchema = invoice_type_1.InvoiceTypeSchema.partial();
exports.InvoicesRequestSchema = zod_1.z.object({
    contract_id: zod_1.z.string().optional(),
    include_expired: zod_1.z.boolean().optional(),
    created_at_from: zod_1.z.string().optional(),
    created_at_to: zod_1.z.string().optional(),
});
exports.RequestEarlyPaymentOnEligibleInvoiceRequestSchema = zod_1.z.object({
    pas_id: zod_1.z.string(),
    discount: invoice_type_1.InvoiceTypeSchema.shape.discount,
});
exports.InvoicesSummaryRequestSchema = zod_1.z.object({
    created_at_from: zod_1.z.string().optional(),
    created_at_to: zod_1.z.string().optional(),
});
