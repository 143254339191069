import autoAnimate from '@formkit/auto-animate';
import React, { useEffect, useRef } from 'react';
import { Outlet } from "react-router-dom";
import { AuthenticatedGuard } from '../guards/AuthenticatedGuard';
import { EULAModal } from '../misc/EULAModal';

interface EmbedLayoutProps {
  children?: React.ReactElement;
}

export const EmbedLayout: (props:EmbedLayoutProps) => JSX.Element | null = ({children}) => {
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  return (
    <>
      <EULAModal/>
      <AuthenticatedGuard />
      <div className="pt-4">
        <div ref={parent}>
          { children ? children : <Outlet /> }
        </div>
      </div>
    </>
  );
}
