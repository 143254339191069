import { Route, Routes } from "react-router-dom";
import { withAdminGuard } from "../components/guards/AdminGuard";
import { EmbedLayout } from "../components/layouts/EmbedLayout";
import { ExternalLayout } from "../components/layouts/ExternalLayout";
import { InternalLayout } from "../components/layouts/InternalLayout";
import { Contracts } from "./contracts/ContractsList";
import { ForgotPassword } from "./ForgotPassword";
import { Home } from "./Home";
import { Invite } from "./Invite";
import { InvoiceEarlyPaymentPage } from "./invoices/invoiceEarlyPayment";
import { Invoices } from "./invoices/InvoicesList";
import { Login } from "./Login";
import { OrganisationProfile } from "./OrganisationProfile";
import { OrganisationsForm } from "./organisations/OrganisationsForm";
import { OrganisationsList } from "./organisations/OrganisationsList";
import { Profile } from "./Profile";
import { ResetPassword } from "./ResetPassword";
import { SignUp } from "./SignUp";
import { UsersForm } from "./users/UsersForm";
import { UsersList } from "./users/UsersList";
import { SupplyChains } from "./supplyChains/SupplyChains";
import { About } from "./About";
import { EULA } from "./EULA";
import { Reports } from "./Reports";
import { EligibleInvoiceEmbedded } from "./eligible-invoices/EligibleInvoiceEmbedded";

interface RouterProps {

}

export const Router: (props:RouterProps) => JSX.Element = () => {
    return (
      <Routes>
        <Route element={<ExternalLayout />}>
          <Route path="/" element={<Login />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/invite-user/:token" element={<ResetPassword isNewUser/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/eula" element={<EULA />} />
        </Route>
        <Route path="/invite" element={<Invite />} />

        <Route element={<InternalLayout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/my-profile" element={<Profile />} />
          <Route path="/my-organisation" element={<OrganisationProfile />} />
          <Route path="/supply-chains" element={<SupplyChains />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/admin/organisations">
            <Route index element={withAdminGuard(<OrganisationsList />)}></Route>
            <Route path="new" element={withAdminGuard(<OrganisationsForm />)}></Route>
            <Route path="edit/:id" element={withAdminGuard(<OrganisationsForm />)}></Route>
          </Route>
          <Route path="/admin/users">
            <Route index element={withAdminGuard(<UsersList />)}></Route>
            <Route path="edit/:id" element={withAdminGuard(<UsersForm />)}></Route>
          </Route>
          <Route path="/admin/contracts">
            <Route index element={withAdminGuard(<Contracts />)}></Route>
          </Route>
          <Route path="/admin/invoices">
            <Route index element={withAdminGuard(<Invoices />)}></Route>
          </Route>
          <Route path="/eligible-invoices">
            <Route path=":id" element={<EligibleInvoiceEmbedded />}></Route>
          </Route>
        </Route>

        <Route path="/embed" element={<EmbedLayout />}>
          <Route path='dashboard' element={(<Home isEmbedded />)}></Route>
          <Route path='invoices'>
            <Route index element={<Reports isEmbedded />}></Route>
            <Route path=':id' element={(<InvoiceEarlyPaymentPage isEmbedded />)}></Route>
          </Route>
          <Route path="eligible-invoices">
            <Route path=":id" element={<EligibleInvoiceEmbedded />}></Route>
          </Route>
          <Route path="eula" element={<EULA isEmbed/>} />
        </Route>
      </Routes>
    );
}
